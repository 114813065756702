.navbar-sider {
  height: 100vh;
  background: #fff;
}

.navbar-menu {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  font-style: normal;
  transition: all 0.3s ease;
}

.navbar-footer {
  text-align: center;
  padding: 16px 0;
}

.navbar-toggle {
  width: 100%;
  font-size: 16px;
}
