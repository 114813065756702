
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--light-background-color);
  overflow: hidden;
}

.darkMode {
  background-color: #141414;
}

.lightMode {
  background-color: #fafafb;
}

.themeToggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.logo {
  width: 350px;
  margin-bottom: 30px;
}

.card {
  width: 100%;
  max-width: 480px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.cardDark {
  background-color: #1f1f1f;
}

.cardLight {
  background-color: #fff;
}

.header {
  text-align: left;
  margin-bottom: 16px;
}

.header-title {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.labelDark {
  color: #aaa;
}

.labelLight {
  color: #666;
}

.login-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.darkMode .login-footer {
  background-color: #1f1f1f;
}

.footer-text {
  color: #666;
  font-size: 12px;
  flex-wrap: wrap;
  padding-left: 22%;
}

.darkMode .footer-text {
  color: #aaa;
}

.footer-links {
  display: flex;
  gap: 10px;
  padding-right: 18.5%;
}

.footer-links a {
  color: #007bff;
  font-size: 12px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.darkMode .footer-links a {
  color: #4a90e2;
}

.textStyle {
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: inherit;
}

@media (max-width: 768px) {
  .login-footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .footer-text {
    margin-bottom: 10px;
    text-align: left;
  }

  .footer-links {
    justify-content: flex-start;
    padding-right: 0;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .form-options {
    flex-direction: column !important;
    align-items: flex-start;
    gap: 10px;
  }
}
