.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: #fff;
  height: 64px;
  border-bottom: 1px solid #f0f0f0;
}

.navbar-logo img {
  height: 50px;
  object-fit: contain;
}

.navbar-search {
  flex: 1;
  max-width: 400px;
  margin: 0 16px;
}

.search-input {
  border-radius: 16px;
  height: 35px;
  border: 1px solid #d9d9d9;
  padding-left: 10px;
 
}

.search-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.navbar-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-actions .ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #555;
  border: none;
}

.navbar-actions .ant-btn:hover {
  color: #1890ff;
}

.navbar-actions .ant-badge .ant-btn {
  background-color: transparent;
  color: #555;
  border: none;
}

.navbar-actions .ant-badge .ant-btn:hover {
  color: #1890ff;
}

.menu-button {
  background: transparent;
  border: none;
  color: #555;
  font-size: 16px;
}

.menu-button:hover {
  color: #1890ff;
}
